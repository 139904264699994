<template>
  <v-container class="page">
    <div class="page-header pb-2">
      <v-btn @click="close()" icon color="blue darken-2">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-checkbox
        dense
        small
        hide-details
        v-model="autoplay"
        color="blue darken-2"
        class="mt-0 pt-0"
      ></v-checkbox>
      <div class="text-caption">Autoplay</div>
    </div>

    <div class="page-content">
      <div class="text-h6">Select the language</div>

      <v-radio-group v-model="language" column>
        <v-card
          outlined
          class="my-2 pe-2 ps-4 py-2 tex-center d-flex justify-space-between"
          width="200"
          max-width="100%"
        >
          Bilingual <v-radio color="blue darken-2" :value="1"></v-radio>
        </v-card>
        <v-card
          outlined
          class="my-2 pe-2 ps-4 py-2 tex-center d-flex justify-space-between"
          width="200"
          max-width="100%"
        >
          English <v-radio color="blue darken-2" :value="2"></v-radio>
        </v-card>
        <v-card
          outlined
          class="my-2 pe-2 ps-4 py-2 tex-center d-flex justify-space-between"
          width="200"
          max-width="100%"
        >
          Mandarin <v-radio color="blue darken-2" :value="3"></v-radio>
        </v-card>
      </v-radio-group>

      <v-btn
        width="200"
        max-width="100%"
        color="blue darken-2"
        depressed
        dark
        @click="goNext"
      >
        OK
      </v-btn>
    </div>
    <div class="page-footer"></div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    autoplay: false,
    language: 1,
  }),
  created() {
    //
  },
  mounted() {
    this.autoplay =
      this.$route.query.autoplay && this.$route.query.autoplay == 1
        ? true
        : false;
  },
  beforeDestroy() {
    //
  },
  methods: {
    goPrevious() {
      this.$router.push({
        name: "ABCPage2",
        query: { autoplay: this.autoplay ? 1 : 0, lang: this.language },
      });
    },
    goNext() {
      this.$router.push({
        name: "ABCPage4",
        query: { autoplay: this.autoplay ? 1 : 0, lang: this.language },
      });
    },
    close() {
      this.$router.push({
        name: "ABCPage2",
      });
    },
  },
};
</script>

<style scoped>
.page-content,
.page-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  border: #000 solid 1px;
  background: rgb(255, 255, 255) !important;
}

.buttons {
  display: flex;
}

.btn-dis {
  opacity: 0.5;
}

.vocab {
  text-decoration: underline;
  font-weight: 900;
}
</style>